import { renderSlot as _renderSlot, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modalWrapper",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideModal && _ctx.hideModal(...args)))
      }, [
        _createElementVNode("div", {
          class: "modalWrapper__content",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ]))
    : _createCommentVNode("", true)
}