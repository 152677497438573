import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mainWrapper" }
const _hoisted_2 = { class: "mainWrapper__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopNavbar = _resolveComponent("TopNavbar")!
  const _component_AuthorizationForm = _resolveComponent("AuthorizationForm")!
  const _component_ModalWindow = _resolveComponent("ModalWindow")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TopNavbar, {
      onOpenModal: _ctx.showModal,
      onSetAuth: _ctx.setAuth,
      "is-auth": _ctx.auth,
      "onUpdate:isAuth": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.auth) = $event))
    }, null, 8, ["onOpenModal", "onSetAuth", "is-auth"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ModalWindow, {
        show: _ctx.modalVisible,
        "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalVisible) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AuthorizationForm, {
            onCloseModal: _ctx.hideModal,
            onSetAuth: _ctx.setAuth
          }, null, 8, ["onCloseModal", "onSetAuth"])
        ]),
        _: 1
      }, 8, ["show"]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view, { onSetAuth: _ctx.setAuth }, null, 8, ["onSetAuth"])
      ])
    ])
  ], 64))
}