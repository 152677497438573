
import MainButton from "@/components/UI/MainButton.vue";
import { defineComponent } from "vue";
export default defineComponent({
  components: { MainButton },
  emits: ["setAuth"],

  data() {
    return {
      username: "",
    };
  },
  methods: {
    logout() {
      this.$router.push("/");
      localStorage.removeItem("currentUser");
      this.$emit("setAuth", false);
    },
  },
  mounted() {
    const currentUser = localStorage.getItem("currentUser");
    if (currentUser) this.username = JSON.parse(currentUser).name;
    this.$emit("setAuth", true);
  },
});
