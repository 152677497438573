import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-253cd68f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accountWrapper" }
const _hoisted_2 = { class: "accountWrapper__controls" }
const _hoisted_3 = { class: "accountWrapper__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainButton = _resolveComponent("MainButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, "Привет, " + _toDisplayString(_ctx.username), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MainButton, { onClick: _ctx.logout }, {
          default: _withCtx(() => [
            _createTextVNode("Выйти из аккаунта")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_MainButton, {
          class: "buttonWrapper_white",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/contacts')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Перейти в контакты")
          ]),
          _: 1
        })
      ])
    ])
  ]))
}