
import { defineComponent } from "vue";
import database from "@/database/users.json";
import IUser from "@/models/IUser";

export default defineComponent({
  name: "AuthorizationForm",
  data() {
    return {
      login: "",
      password: "",
      loginError: "",
      passwordError: "",
      authError: "",
    };
  },
  methods: {
    validateLogin() {
      this.loginError = this.login.length === 0 ? "Введите логин!" : "";
    },
    validatePassword() {
      this.passwordError =
        this.password.length < 8
          ? "Длина пароля должна быть не меньше 8 символов!"
          : "";
    },

    validateForm(): void {
      this.validateLogin();
      this.validatePassword();

      if (this.loginError.length === 0 && this.passwordError.length === 0) {
        const authorizedUsers: IUser[] = database.users;
        const matchedUser = authorizedUsers.find(
          (user) => user.login === this.login && user.password === this.password
        );
        if (matchedUser) {
          localStorage.setItem("currentUser", JSON.stringify(matchedUser));
          this.$router.push("/account");
          this.$emit("closeModal");
        } else {
          this.authError = "Неверный логин или пароль";
        }
      }
    },
  },
});
