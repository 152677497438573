
import { defineComponent } from "vue";
import MenuCard from "@/components/MenuCard.vue";
import MainButton from "@/components/UI/MainButton.vue";
import ModalWindow from "@/components/UI/ModalWindow.vue";
import AuthorizationForm from "@/components/AuthorizationForm.vue";

export default defineComponent({
  components: { MenuCard, MainButton, ModalWindow, AuthorizationForm },
  emits: ["setAuth"],

  data() {
    return {
      menuItems: [
        {
          id: 1,
          title: "Онлайн-прием",
          image: require("@/assets/images/heart.svg"),
          text: "Рыба текст",
        },
        {
          id: 2,
          title: "Экстренный Случай",
          image: require("@/assets/images/stethoscope.svg"),
          text: "Рыба текст",
        },
        {
          id: 3,
          title: "Лечение рака",
          image: require("@/assets/images/med-history.svg"),
          text: "Рыба текст",
        },
      ],
      modalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.modalVisible = true;
    },
  },
  mounted() {
    localStorage.removeItem("currentUser");
    this.$emit("setAuth", false);
  },
});
