
import { defineComponent } from "vue";

export default defineComponent({
  name: "TopNavbar",
  props: {
    isAuth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalVisible: false,
    };
  },
  methods: {
    showModal() {
      this.$emit("openModal");
    },

    logout() {
      this.$router.push("/");
      localStorage.removeItem("currentUser");
    },
  },
});
