
import { defineComponent } from "vue";
import TopNavbar from "@/components/TopNavbar.vue";
import ModalWindow from "@/components/UI/ModalWindow.vue";
import AuthorizationForm from "@/components/AuthorizationForm.vue";

export default defineComponent({
  components: { TopNavbar, ModalWindow, AuthorizationForm },
  data() {
    return {
      modalVisible: false,
      isAuthorized: false,
      auth: false,
    };
  },
  methods: {
    showModal() {
      this.modalVisible = true;
    },
    hideModal() {
      this.modalVisible = false;
    },
    setAuth(value: boolean) {
      console.log(value);
      this.auth = value;
    },
  },
});
